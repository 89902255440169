import { OrderFlowEnum } from '@goparrot/common';
import { UserTypeEnum } from '@goparrot/customer-sdk';
import type { IOrder, IOrderElement, IOrderPromotionElement } from '@goparrot/order-sdk';
import { PromotionSubTypeEnum } from '@goparrot/promotions-sdk';
import {
  useClearCartMutation,
  useGetFullCart,
  useRemoveGiftCardFromCart,
  useRemoveItemFromCart,
  useUpdateGiftCardQuantity,
  useUpdateQuantity,
} from '@webstore-monorepo/shared/api/cart-api';
import { useGetApplicableLoyalties, useRemoveLoyaltyReward } from '@webstore-monorepo/shared/api/loyalty-api';
import { useGetApplicablePromotions, useRemovePromotionFromCart } from '@webstore-monorepo/shared/api/promotions-api';
import { useCartDispatch, useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useMerchantState } from '@webstore-monorepo/shared/contexts/merchant-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useUserContextState } from '@webstore-monorepo/shared/contexts/user-context-provider';
import { getCartItems, useExtractCombos } from '@webstore-monorepo/shared/utils/cart';
import { pluralise } from '@webstore-monorepo/shared/utils/text';
import { CircleBadge } from '@webstore-monorepo/ui/badge';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { Collapsable } from '@webstore-monorepo/ui/collapsable';
import { UiConfirmationModal } from '@webstore-monorepo/ui/confirmation-modal';
import { Separator } from '@webstore-monorepo/ui/separator';
import { Text } from '@webstore-monorepo/ui/text';
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import type { RefObject } from 'react';
import { createRef } from 'react';
import React, { useState } from 'react';

import { OrderComboList } from './combo-items';
import { GiftCardsList } from './gift-cards-list';
import { OrderItem } from './OrderItem';

export interface OrderItemsListProps {
  isReadOnly?: boolean;
  itemsRef?: RefObject<HTMLElement>[];
  isDefaultOpen?: boolean;
  onEditItemClick?: (itemId: string, state?: any) => void;
  onComboEdit?: (uid: string, uniqueName: string) => void;
  onGiftCardEdit?: (uid: string) => void;
}

export const OrderItemsList: React.FC<OrderItemsListProps> = ({ isReadOnly, itemsRef, isDefaultOpen = true, onEditItemClick, onComboEdit, onGiftCardEdit }) => {
  const cart = useCartState();
  const { user } = useUserContextState();
  const isAuthUser = user.type === UserTypeEnum.AUTHENTICATED;
  const cartDispatch = useCartDispatch();
  const cartItems = getCartItems(cart);
  const combos = useExtractCombos(cart);
  const { dictionary } = useMerchantState();
  const [isClearCartConfirmationVisible, setIsClearCartConfirmationVisible] = useState<boolean>(false);
  const groupedMultiBrands = groupBy(cartItems, 'menuConceptUuid');
  const { analytics, intl, notification } = usePlatformStoreState();
  const [isExpanded, setIsExpanded] = useState<boolean>(isDefaultOpen);
  const itemsCount = cart?.flow === OrderFlowEnum.DIGITAL ? cart.giftcards?.length ?? 0 : Object.keys(combos).length + cartItems.length;
  const { mutate: onClearCart } = useClearCartMutation({
    onSuccess: async (cart) => {
      cartDispatch({ type: 'initiate', payload: cart });
      analytics.track('cart_clear_confirm', {});
      notification.success(intl.getIntlString('cart.cleared'));
    },
    onError: () => {
      notification.error(intl.getIntlString('cart.clear.error'));
    },
    onSettled: () => {
      handleHideClearCartModal();
    },
  });
  const { data: promotionsData } = useGetApplicablePromotions();
  const { data: loyalties } = useGetApplicableLoyalties({
    cacheTime: 0,
    enabled: isAuthUser,
  });

  const { mutateAsync: handleRemovePromotionFromCart } = useRemovePromotionFromCart();
  const { refetch: getFullCart, isLoading: isFullCartLoading } = useGetFullCart({
    enabled: false,
    onSuccess: (updatedCart) => {
      cartDispatch({ type: 'update', payload: cloneDeep(updatedCart) });
    },
  });

  const { mutate: handleUpdateQuantity } = useUpdateQuantity({
    onSuccess: (cart, { item, quantity }) => {
      analytics.track('cart_item_quantity_change', {
        uid: item.uniqueName,
        newQuantity: quantity,
        oldQuantity: item.quantity,
      });

      handleUpdateCart(cart);
    },
    onError: (error) => {
      if (error.response?.data?.message?.includes('maximum allowed weight')) {
        notification.error(error.response?.data?.message);
      }
    },
  });

  const { mutate: handleRemoveItem } = useRemoveItemFromCart({
    onSuccess: async (updatedCart, item) => {
      if (updatedCart) {
        if (!item.tag?.promotionUuid) {
          const promoItem = updatedCart.elements.find((el) => el.uniqueNameOriginal === item.uniqueNameOriginal);
          const promo = cart.promotions.find((promo) => promo.uuid === promoItem?.tag?.promotionUuid);
          const itemConfigMinQuantity = Math.max(item.metadata?.minQuantity || 0, 1);

          if (promoItem && promo && promo.quantity < itemConfigMinQuantity) {
            await handleRemovePromoFromCart(promo, false);
            return;
          }
        }
        handleUpdateCart(updatedCart);
        analytics.track('cart_item_delete', {
          uid: item.uniqueName,
        });
        notification.success(`<strong>${item.title}</strong> was removed from your cart.`);
      }
    },
    onError: () => {
      notification.error('Error: can not remove item from your cart.');
    },
  });

  const { mutate: handleRemoveGiftCard } = useRemoveGiftCardFromCart({
    onSuccess: async () => {
      await getFullCart();
      notification.success(`Gift card was removed from your cart. `);
    },
    onError: () => {
      notification.error('Error: can not remove gift card from your cart.');
    },
  });

  const { mutate: updateGiftCartQuantity } = useUpdateGiftCardQuantity({
    onSuccess: async () => {
      await getFullCart();
    },
    onError: () => {
      notification.error(`We're sorry. Gift card quantity wasn't updated. Try again!`);
    },
  });

  const { mutateAsync: handleRemoveLoyaltyReward } = useRemoveLoyaltyReward();

  const onToggleCollapse = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleChangeItemQuantity = (item: IOrderElement) => (value: number) => {
    if (!value) return;
    handleUpdateQuantity({
      item,
      uniqueName: item.uniqueName,
      quantity: value,
    });
  };

  const handleUpdateCart = (cart: IOrder) => {
    cartDispatch({ type: 'update', payload: cloneDeep(cart) });
  };

  const handleRemoveItemFromCart = (item: IOrderElement) => {
    handleRemoveItem(item);
  };

  const handleRemoveGiftCardFromCart = (giftCardId: string) => {
    handleRemoveGiftCard(giftCardId);
  };

  const handleUpdateGiftCardQuantity = (giftCardId: string, quantity: number) => {
    updateGiftCartQuantity({ giftCardId, quantity });
  };

  const handleRemovePromoFromCart = async (promo: IOrderPromotionElement, needToShowMessage = true) => {
    try {
      if (PromotionSubTypeEnum.LOYALTY === promo.subtype) {
        await handleRemoveLoyaltyReward(promo.loyaltyInfo.rule.uuid);
        analytics.track('cart_reward_deleted', {
          uuid: promo.loyaltyInfo.rule.uuid,
        });
      } else {
        await handleRemovePromotionFromCart(promo.uuid);

        analytics.track('cart_promo_deleted', {
          uuid: promo.uuid,
          promoCode: promo.promoCode,
        });
      }
      getFullCart();
      if (needToShowMessage) {
        notification.success(`<strong>${promo.title}</strong> was removed from your cart.`);
      }
    } catch (error) {
      notification.error('Error: can not remove item from your cart.');
    }
  };

  const handleShowClearCartModal = () => {
    setIsClearCartConfirmationVisible(true);
  };

  const handleHideClearCartModal = () => {
    setIsClearCartConfirmationVisible(false);
  };

  const renderTitle = () => (
    <Text fontSize="xs" fontWeight="500" textTransform="uppercase">
      {isReadOnly ? 'Order Details' : 'Your Order'}
    </Text>
  );

  const renderContent = () => (
    <Box marginTop={isReadOnly ? 3 : 'none'} testID="checkout-items-list">
      {Object.keys(groupedMultiBrands).map((menuConceptKey) => (
        <Box key={menuConceptKey}>
          {groupedMultiBrands[menuConceptKey].map((item, index) => (
            <OrderItem
              ref={itemsRef && (itemsRef[index] = createRef())}
              isReadOnly={isReadOnly}
              item={item}
              key={item.uniqueName}
              onChangeItemQuantity={handleChangeItemQuantity(item)}
              promotions={promotionsData?.promotions}
              loyalties={loyalties}
              onRemoveItemFromCart={handleRemoveItemFromCart}
              onEditItemClick={onEditItemClick}
              loyaltyPoints={dictionary.loyaltyPoints}
            />
          ))}
        </Box>
      ))}
      <OrderComboList onComboEdit={onComboEdit} isEditable={!isReadOnly} />
      {cart?.giftcards?.length ? (
        <GiftCardsList
          cards={cart.giftcards}
          onRemoveGiftCard={handleRemoveGiftCardFromCart}
          onUpdateQuantity={handleUpdateGiftCardQuantity}
          onGiftCardEdit={onGiftCardEdit}
          isReadOnly={isReadOnly}
        />
      ) : null}
    </Box>
  );

  return (
    <Box marginBottom={!isReadOnly || (isReadOnly && isExpanded) ? 8 : 'none'} testID="cart-items">
      {isReadOnly ? (
        <>
          <Collapsable
            isDefaultOpen={isExpanded}
            onToggleCollapse={onToggleCollapse}
            title={
              <Box testID="order-items-list__ready-only" flexGrow={1} flexDirection="row" justifyContent="space-between" alignItems="center">
                {renderTitle()}
                <CircleBadge
                  backgroundColor="gray100"
                  top={0}
                  right={0}
                  left={0}
                  position="relative"
                  paddingTop={1}
                  paddingBottom={1}
                  paddingLeft={2}
                  paddingRight={2}
                  width="auto"
                  height="auto"
                >
                  <Text color="gray500">
                    <>
                      {itemsCount} item{pluralise(itemsCount)}
                    </>
                  </Text>
                </CircleBadge>
              </Box>
            }
          >
            {renderContent()}
          </Collapsable>
          {isExpanded ? null : (
            <Box marginTop={8}>
              <Separator />
            </Box>
          )}
        </>
      ) : (
        <>
          <Box marginBottom={4} flexDirection="row" justifyContent="space-between" alignItems="center">
            {renderTitle()}
            <Button onPress={handleShowClearCartModal} variant="link" buttonStyle={{ fontSize: 's' }} testID="cart-remove-all-btn">
              Remove All
            </Button>
          </Box>
          {renderContent()}
          <UiConfirmationModal
            onClose={handleHideClearCartModal}
            isVisible={isClearCartConfirmationVisible}
            onConfirm={onClearCart}
            isLoading={isFullCartLoading}
            confirmText="Remove"
            title="Remove items from cart"
            description="This action will clear all items from your cart. Please note, this action is irreversible."
          />
        </>
      )}
    </Box>
  );
};
