import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import React from 'react';

interface Props {
  header?: string;
  description?: string;
}
export const OrderItemWarningText = ({ header, description }: Props) => {
  return (
    <Box marginTop={3} testID="item-warning">
      {header ? (
        <Text lineHeight={22} fontSize="l" color="danger" fontWeight="600">
          {header}
        </Text>
      ) : null}
      {description ? (
        <Text lineHeight={22} fontSize="m" color="danger">
          {description}
        </Text>
      ) : null}
    </Box>
  );
};
